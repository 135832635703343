/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSettings } from './accountSettings';
import { Account } from './account';
import { AccountDocument } from './accountDocument';
import { AdminExtendedBankAccount } from './adminExtendedBankAccount';
import { AdminAccountCapability } from './adminAccountCapability';
import { BusinessProfile } from './businessProfile';
import { TerminalReader } from './terminalReader';
import { AccountInternalMetadata } from './accountInternalMetadata';


export interface AdminExtendedAccount { 
    /**
     * Bank accounts attached to this account. Primarily used for payouts.
     */
    bank_accounts: Array<AdminExtendedBankAccount>;
    /**
     * The Business Profile for this account.
     */
    business_profile?: BusinessProfile;
    /**
     * Capabilities represent the assigned product codes to a given merchant account and their status.
     */
    capabilities?: Array<AdminAccountCapability>;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    deleted_at?: string;
    /**
     * Documents that have been requested for an account, which are either in the `requested` or `submitted` status
     */
    document_requests: Array<AccountDocument>;
    /**
     * The primary user\'s email address.
     */
    email?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    internal_metadata?: AccountInternalMetadata;
    /**
     * Whether Tilled is providing support for this account.
     * @deprecated
     */
    merchant_support?: boolean;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * The business name or individual name.
     */
    name?: string;
    parent_account?: Account;
    /**
     * The account settings object.
     */
    settings?: AccountSettings;
    /**
     * Terminal Readers attached to this account.
     */
    terminal_readers: Array<TerminalReader>;
    /**
     * The Tilled account type. Can be `partner` or `merchant`.
     */
    type: AdminExtendedAccount.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The `partner` white label domain.
     */
    white_label_domain?: string;
}
export namespace AdminExtendedAccount {
    export type TypeEnum = 'partner' | 'merchant' | 'reseller';
    export const TypeEnum = {
        PARTNER: 'partner' as TypeEnum,
        MERCHANT: 'merchant' as TypeEnum,
        RESELLER: 'reseller' as TypeEnum
    };
}


