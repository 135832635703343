/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminBillingPaymentProviderConfigData { 
    accountId: string;
    accountMonthlyFeeEnabled?: boolean;
    billingEnabled: boolean;
    billingOwner: AdminBillingPaymentProviderConfigData.BillingOwnerEnum;
    collectionInterval?: number;
    collectionIntervalAnchor?: string;
    collectionIntervalUnit?: AdminBillingPaymentProviderConfigData.CollectionIntervalUnitEnum;
    id: string;
    paymentProviderId: string;
    payoutEnabled: boolean;
    payoutInterval?: number;
    payoutIntervalAnchor?: string;
    payoutIntervalUnit?: AdminBillingPaymentProviderConfigData.PayoutIntervalUnitEnum;
    payoutOwner: AdminBillingPaymentProviderConfigData.PayoutOwnerEnum;
    providerConfigErrors?: Array<string>;
    providerConfigStatus?: AdminBillingPaymentProviderConfigData.ProviderConfigStatusEnum;
    settlementType?: AdminBillingPaymentProviderConfigData.SettlementTypeEnum;
    useMock?: boolean;
}
export namespace AdminBillingPaymentProviderConfigData {
    export type BillingOwnerEnum = 'provider' | 'tilled';
    export const BillingOwnerEnum = {
        PROVIDER: 'provider' as BillingOwnerEnum,
        TILLED: 'tilled' as BillingOwnerEnum
    };
    export type CollectionIntervalUnitEnum = 'day' | 'week' | 'month';
    export const CollectionIntervalUnitEnum = {
        DAY: 'day' as CollectionIntervalUnitEnum,
        WEEK: 'week' as CollectionIntervalUnitEnum,
        MONTH: 'month' as CollectionIntervalUnitEnum
    };
    export type PayoutIntervalUnitEnum = 'day' | 'week' | 'month';
    export const PayoutIntervalUnitEnum = {
        DAY: 'day' as PayoutIntervalUnitEnum,
        WEEK: 'week' as PayoutIntervalUnitEnum,
        MONTH: 'month' as PayoutIntervalUnitEnum
    };
    export type PayoutOwnerEnum = 'provider' | 'tilled';
    export const PayoutOwnerEnum = {
        PROVIDER: 'provider' as PayoutOwnerEnum,
        TILLED: 'tilled' as PayoutOwnerEnum
    };
    export type ProviderConfigStatusEnum = 'valid' | 'invalid';
    export const ProviderConfigStatusEnum = {
        VALID: 'valid' as ProviderConfigStatusEnum,
        INVALID: 'invalid' as ProviderConfigStatusEnum
    };
    export type SettlementTypeEnum = 'net' | 'gross';
    export const SettlementTypeEnum = {
        NET: 'net' as SettlementTypeEnum,
        GROSS: 'gross' as SettlementTypeEnum
    };
}


